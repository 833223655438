
import React from 'react';
import bgImage from '../../../assets/img/BackGround/ITLCTRNC_FONDO_INDUSTRIAL.png';
import logo from '../../../assets/img/Logos_Intelectronica_.png';
import { FaInstagram, FaLinkedinIn, FaWhatsapp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="bg-light-gray text-white py-8 px-6 md:px-20">
      {/* Background Image Section */}
      {/* <div
        className="w-full h- bg-cover bg-no-repeat bg-top mb-8"
        style={{ backgroundImage: `url(${bgImage})` }}
      ></div>
       */}
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Logo and Mission Statement */}
        <div className="flex flex-col md:flex-row items-center md:items-start md:justify-center">
          <img src={logo} alt="INTELECTRONICA Logo" className="h-32 w-auto mb-4 md:mb-0 md:mr-4" />
          <p className="text-center md:text-left text-lg md:text-2xl p-3 font-sans">{t('footer.missionStatement')}</p>
        </div>

        {/* Contact Information */}
        <div className="text-center md:text-left md:mx-auto">
          <h4 className="text-lg text-medium-blue font-semibold mt-2 mb-2">{t('footer.contactUs')}</h4>
          <a href='mailto:comercial@intelectronica.com.co' className='hover:underline '>{t('footer.email')}</a>
          <h4 className="text-lg text-medium-blue font-semibold mt-8 mb-2">{t('footer.locationTitle')}</h4>
          <p>{t('footer.location')}</p>
        </div>

        {/* Quick Links */}
        <div className="text-center md:text-left md:mx-auto">
          <h4 className="text-lg text-medium-blue font-semibold mb-2">{t('footer.quickLinks')}</h4>
          <ul className='mt-2'>
            <li><a href="#about" className="hover:underline">{t('footer.aboutUs')}</a></li>
            <li className='mt-2'><a href="#privacy" className="hover:underline">{t('footer.privacyPolicy')}</a></li>
            <li className='mt-2'><a href="#terms" className="hover:underline">{t('footer.termsOfService')}</a></li>
          </ul>
        </div>
      </div>

      {/* Social Media Links */}
      <div className="flex justify-center space-x-4 mt-10">
        <a href="https://www.instagram.com/intelectronica_sas/" className="text-white hover:text-pink-500 text-xl"><FaInstagram /></a>
        <a href="https://www.linkedin.com/company/intelectronica-s-a-s/?originalSubdomain=co" className="text-white hover:text-blue-700 text-xl"><FaLinkedinIn /></a>
        <a href="https://wa.me/+573127725739" className="text-white hover:text-green-500 text-xl"><FaWhatsapp /></a>
      </div>

      {/* Footer Bottom */}
      <div className="mt-5 text-center text-sm">
        <p>&copy; {t('footer.company')} {t('footer.rights')}</p>
      </div>
    </footer>
  );
}

export default Footer;
