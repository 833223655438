import React from 'react';
import { createRoot } from 'react-dom/client';
import './components/styles/tailwind.css';
import App from './App';
import './utils/i18n/i18n.jsx'; 
import reportWebVitals from './utils/reportWebVitals';


const container = document.getElementById('root');
const root = createRoot(container);


root.render(<App tab="home" />);

reportWebVitals((metric) => {
  if (window.gtag) {
    window.gtag('event', metric.name, {
      event_category: 'Web Vitals',
      value: metric.value,
      event_label: metric.id, // ID for FID, CLS, etc.
      non_interaction: true, // Avoid affecting bounce rate
    });
  }
});