import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackPageview } from './Analytics.jsx';

const GoogleAnalyticsTracker = () => {
  const location = useLocation();

  useEffect(() => {
    trackPageview(location.pathname);
  }, [location]);

  return null;
};

export default GoogleAnalyticsTracker;