import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ScrollReveal from 'scrollreveal';

const OurTimeline = () => {
  const { t } = useTranslation();

  useEffect(() => {
    ScrollReveal().reveal('.timeline-content .step', {
      origin: 'bottom',
      distance: '20px',
      duration: 500,
      delay: 200,
      interval: 100,
      reset: false
    });
  }, []);

  return (
    <section>
      <div className="bg-dark-blue text-white py-8">
        <div className="container mx-auto flex flex-col items-start md:flex-row my-12 md:my-24">
          <div className="flex flex-col w-full sticky md:top-36 lg:w-1/3 mt-2 md:mt-12 px-8">
            <p className=" text-medium-blue text-xl uppercase tracking-loose">{t('ourTimeline.workingProcess')}</p>
            <p className="text-3xl md:text-4xl leading-normal md:leading-relaxed mb-2">{t('ourTimeline.workingProcessTitle')}</p>
            <p className=" md:text-base text-gray-50 mb-4">
              {t('ourTimeline.workingProcessDescription')}
            </p>
            {/* <a href="#"
              className="bg-transparent mr-auto hover:bg-medium-blue text-medium-blue hover:text-white rounded shadow hover:shadow-lg py-2 px-4 border border-medium-blue hover:border-transparent">
              {t('ourTimeline.exploreNow')}
            </a> */}
          </div>
          <div className="ml-0 md:ml-12 lg:w-2/3 sticky">
            <div className="container mx-auto w-full h-full">
              <div className="relative wrap overflow-hidden p-10 h-full timeline-content">
                <div className="border-2-2 border-medium-blue absolute h-full border"
                  style={{ right: '50%', border: '1px solid #21BFFB', borderRadius: '1%' }}></div>
                <div className="border-2-2 border-medium-blue absolute h-full border"
                  style={{ left: '50%', border: '1px solid #21BFFB', borderRadius: '1%' }}></div>
                {t('ourTimeline.steps', { returnObjects: true }).map((step, index) => (
                  <div key={index} className={`mb-8 flex justify-between ${index % 2 === 0 ? 'flex-row-reverse' : ''} items-center w-full ${index % 2 === 0 ? 'left-timeline' : 'right-timeline'} step`}>
                    <div className="order-1 w-5/12"></div>
                    <div className="order-1 w-5/12 px-1 py-4 text-right">
                      <p className="mb-3 text-base text-medium-blue">{step.date}</p>
                      <h4 className="mb-3 font-bold text-lg md:text-2xl">{step.title}</h4>
                      <p className="text-sm md:text-base leading-snug text-gray-50 text-opacity-100">
                        {step.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              {/* <img className="mx-auto -mt-36 md:-mt-36" src="https://user-images.githubusercontent.com/54521023/116968861-ef21a000-acd2-11eb-95ac-a34b5b490265.png" alt="Process illustration" /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurTimeline;
