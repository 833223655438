// utils/analytics.js
export const trackPageview = (url) => {
    if (window.gtag) {
      window.gtag('config', 'G-J0QXKLWH6F', {
        page_path: url,
      });
    }
  };
  
  export const trackEvent = ({ action, category, label, value }) => {
    if (window.gtag) {
      window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value,
      });
    }
  };