import React, { useState, useEffect } from "react";
import "./components/styles/App.css";
import './components/styles/tailwind.css';
import Navbar from "./components/molecules/Navbar/Navbar.jsx";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home.jsx";
import SignUp from "./pages/SignUp.jsx";
import Demo from "./pages/Demo.jsx";
import VegetalSection from "./pages/VegetalSection.jsx";
import IndustrialSection from "./pages/IndustrialSection.jsx";
import './utils/i18n/i18n.jsx';
import BoltLoaderComponent from "./components/atoms/Loader/Loader.jsx"; 
import GoogleAnalyticsTracker from './utils/GoogleAnalyticsTracker';

function App() {
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); 
    }, 2000);
    
    return () => clearTimeout(timer); 
  }, []);

  if (loading) {
    return <BoltLoaderComponent />;
  }

  return (
    <>
      <Router>
        <GoogleAnalyticsTracker />
        <Navbar />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/vegetalSection" element={<VegetalSection />} />
          <Route path="/industrialSection" element={<IndustrialSection />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;

