import React, { useState, useEffect } from "react";
import { Element } from "react-scroll";
import Link from "react-router-dom"
import ScrollReveal from "scrollreveal";
import CenterCarousel from "../../atoms/Carousel/Carousel.jsx";
import img1 from "../../../assets/img/INDUSTRIAL/Auto_Fotos/Asesoria especializada_6720*4480.jpeg";
import img2 from "../../../assets/img/INDUSTRIAL/Auto_Fotos/Automatización_6720*4480.png";
import img3 from "../../../assets/img/INDUSTRIAL/Auto_Fotos/Rendimiento continuo_6720*4480.png";
import img4 from "../../../assets/img/INDUSTRIAL/Auto_Fotos/Soluciones-Inteligentes_6720*4480.png";
import industrialLogo from "../../../assets/img/OurServices/IndustrialLogo.png";
import { useTranslation } from 'react-i18next';
import '@fortawesome/fontawesome-free/css/all.min.css';
import bgImage from '../../../assets/img/BackGround/ITLCTRNC_FONDO_INDUSTRIAL.png';
import industrialLogo01 from '../../../assets/img/IndustrialLogos/INDUSTRIAL_LOGO_01.svg'
import industrialLogo02 from '../../../assets/img/IndustrialLogos/INDUSTRIAL_LOGO_02.svg'
import industrialLogo03 from '../../../assets/img/IndustrialLogos/INDUSTRIAL_LOGO_03.svg'
import industrialLogo04 from '../../../assets/img/IndustrialLogos/INDUSTRIAL_LOGO_04.svg'
import industrialLogo05 from '../../../assets/img/IndustrialLogos/INDUSTRIAL_LOGO_05.svg'
// import industrialLogo06 from '../../../assets/img/IndustrialLogos/INDUSTRIAL_LOGO_06.svg'

const carouselIndustrialImages = [
  {
    src: img2,
    alt: "Indsutrial Image",
    key: "industrial"
  },
  {
    src: img3,
    alt: "Maintenance Image",
    key: "maintenanceIndustrial"
  },
  {
    src: img1,
    alt: "Advisory Image",
    key: "advisoryIndustrial"
  },
  {
    src: img4,
    alt: "Visualization Image",
    key: "visualizationIndustrial"
  }
];

const IndustrialSection = () => {
  const [currentIndustrialSlide, setCurrentIndustrialSlide] = useState(0);
  const { t } = useTranslation();

  const handleIndustrialClick = (index) => {
    setCurrentIndustrialSlide(index);
  };

  useEffect(() => {
    ScrollReveal().reveal('.industrial-logo, .our-company-content, .hero_buttons, .carousel-text, .carousel-images, .carousel-description, .service-card', {
      origin: 'bottom',
      distance: '20px',
      duration: 500,
      delay: 200,
      interval: 100,
      reset: false
    });
  }, []);

  return (

    <section
      className="p-6 md:p-12 lg:p-20 bg-cover bg-center text-white font-sans"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className="flex flex-col justify-center items-center space-y-10 text-center">
        <img src={industrialLogo} alt="Industrial Logo" className="industrial-logo mt-36 mb-8 h-16 sm:h-20 md:h-24 w-auto" />

        <h1 className="our-company-content mt-4 text-2xl sm:text-3xl md:text-4xl font-bold mb-4 px-4">
          {t('industrialPage.industrialHeroTitle')}
          {/* dangerouslySetInnerHTML={{ __html: t('industrialPage.industr÷ialHeroTitle') }} */}
         </h1>

        <div className="hero_buttons mt-6 md:mt-20 flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
          <a
            href="/demo"
            className="start-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="bg-transparent mr-auto hover:bg-medium-blue text-medium-blue hover:text-white rounded shadow hover:shadow-lg py-2 px-4 border border-medium-blue hover:border-transparent">
              {/* <FontAwesomeIcon icon="arrow-up-right-from-square" className="text-xl pr-2" /> */}
              <i className="fa-solid fa-arrow-up-right-from-square text-xl pr-2"></i>
              {t('industrialPage.demoButton')}
            </button>
          </a>
          <a
            href="https://wa.me/+573127725739"
            className="start-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="bg-transparent mr-auto hover:bg-medium-blue text-medium-blue hover:text-white rounded shadow hover:shadow-lg py-2 px-4 border border-medium-blue hover:border-transparent">
              {/* <FontAwesomeIcon icon={['fab', 'whatsapp']} className="text-2xl pr-2" /> */}
              <i className="fa-brands fa-whatsapp text-2xl pr-2"></i>
              {t('industrialPage.callActionButton')}
            </button>
          </a>
        </div>
      </div>

      <section className="p-6 md:p-12 lg:p-20 text-black font-sans">
        <div className="carousel-description mt-10 w-full grid grid-cols-1 sm:grid-cols-2 gap-6">
          {carouselIndustrialImages.map((image, index) => (
            <div
              key={index}
              onClick={() => handleIndustrialClick(index)}
              className={`cursor-pointer p-4 transition-all duration-300 ease-in-out rounded-lg shadow-lg hover:shadow-xl ${index === currentIndustrialSlide ? "bg-light-blue" : "bg-zinc-100"}`}
            >
              <h3 className={`text-lg sm:text-xl md:text-2xl ${index === currentIndustrialSlide ? "text-black font-bold" : "text-gray-400"} transition-all duration-300 ease-in-out`}>
                {t(`industrialPage.${image.key}.title`)}
              </h3>
              <h4 className={`text-sm sm:text-md md:text-lg ${index === currentIndustrialSlide ? "font-bold mb-2" : "font-normal mb-2"} transition-all duration-300 ease-in-out`}>
                {t(`industrialPage.${image.key}.subtitle`)}
              </h4>
              {index === currentIndustrialSlide && (
                <p className="text-sm sm:text-md font-light transition-opacity duration-300 ease-in-out">
                  {t(`industrialPage.${image.key}.description`)}
                </p>
              )}
            </div>
          ))}
        </div>

        <div className="carousel-images w-full mt-20 md:mt-40">
          <CenterCarousel
            images={carouselIndustrialImages}
            activeItem={currentIndustrialSlide}
            onItemChange={setCurrentIndustrialSlide}
          />
        </div>
      </section>

      <section className="p-6 mt-10 md:mt-5 md:p-5 lg:p-20 bg-card text-white font-sans">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
          <div className="service-card bg-popover p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300 flex flex-row items-center text-left">
            <img src={industrialLogo03} alt="Advisory Icon" className="w-12 sm:w-14 md:w-16 h-12 sm:h-14 md:h-16 mr-4 sm:mr-6" />
            <div>
              {/* <h3 className="text-md sm:text-lg md:text-xl font-bold text-secondary">
                  {t('industrialPage.advisory.title')}
                </h3> */}
              <p className="text-base sm:text-md text-muted-foreground">
                {t('industrialPage.ourServices.automationCard')}
              </p>
            </div>
          </div>

          <div className="service-card bg-popover p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300 flex flex-row items-center text-left">
            <img src={industrialLogo02} alt="Advisory Icon" className="w-12 sm:w-14 md:w-16 h-12 sm:h-14 md:h-16 mr-4 sm:mr-6" />
            <div>
              {/* <h3 className="text-md sm:text-lg md:text-xl font-bold text-secondary">
                  {t('industrialPage.design.title')}
                </h3> */}
              <p className="text-base sm:text-md text-muted-foreground">
                {t('industrialPage.ourServices.electricalCard')}
              </p>
            </div>
          </div>

          <div className="service-card bg-popover p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300 flex flex-row items-center text-left">
            <img src={industrialLogo01} alt="Advisory Icon" className="w-12 sm:w-14 md:w-16 h-12 sm:h-14 md:h-16 mr-4 sm:mr-6" />
            <div >
              {/* <h3 className="text-md sm:text-lg md:text-xl font-bold text-secondary">
                  {t('industrialPage.systemIntegration.title')}
                </h3> */}
              <p className="text-base sm:text-md text-muted-foreground">
                {t('industrialPage.ourServices.technologyCard')}
              </p>
            </div>
          </div>

          <div className="service-card bg-popover p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300 flex flex-row items-center text-left">
            <img src={industrialLogo05} alt="Advisory Icon" className="w-12 sm:w-14 md:w-16 h-12 sm:h-14 md:h-16 mr-4 sm:mr-6" />
            <div>
              {/* <h3 className="text-md sm:text-lg md:text-xl font-bold text-secondary">
                  {t('industrialPage.maintenanceSupport.title')}
                </h3> */}
              <p className="text-base sm:text-md text-muted-foreground">
                {t('industrialPage.ourServices.healthCard')}
              </p>
            </div>
          </div>

          <div className="service-card bg-soft-blue p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300 flex flex-row items-center text-left">
            <img src={industrialLogo04} alt="Advisory Icon" className="w-12 sm:w-14 md:w-16 h-12 sm:h-14 md:h-16 mr-4 sm:mr-6" />
            <div>
              {/* <h3 className="text-md sm:text-lg md:text-xl font-bold text-secondary">
                  {t('industrialPage.supervision.title')}
                </h3> */}
                <p className="text-base sm:text-md text-muted-foreground">
                  {t('industrialPage.ourServices.visualizationCard')}
                </p>
              </div>
            </div>

            <div className="service-card bg-soft-blue p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300 flex flex-row items-center text-left">
              <img src={industrialLogo04} alt="Advisory Icon" className="w-12 sm:w-14 md:w-16 h-12 sm:h-14 md:h-16 mr-4 sm:mr-6" />
              <div>
                {/* <h3 className="text-md sm:text-lg md:text-xl font-bold text-secondary">
                  {t('industrialPage.supervision.title')}
                </h3> */}
                <p className="text-base sm:text-md text-muted-foreground">
                  {t('industrialPage.ourServices.comunicationCard')}
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>
  );
};

export default IndustrialSection;