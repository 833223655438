import React, { useEffect } from 'react';
import ScrollReveal from 'scrollreveal';
import { useTranslation } from 'react-i18next';

const FinalSection = () => {
  const { t } = useTranslation();

  useEffect(() => {
    ScrollReveal().reveal('.final-section-content', {
      origin: 'bottom',
      distance: '20px',
      duration: 1000,
      delay: 200,
      interval: 100,
      reset: false,
    });
  }, []);

  return (
    <div className="bg-dark-blue text-white font-sans">
      <section className="final-section-content text-center p-6 md:p-20">
        <h2 className="text-xl md:text-3xl font-bold mt-10 md:mt-5 mb-4 md:mb-10">
          {t('finalSection.text')}
        </h2>
        <a
          href="https://wa.me/+573127725739"
          className="end-button"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="bg-transparent mr-auto hover:bg-medium-blue text-medium-blue hover:text-white rounded shadow hover:shadow-lg py-2 px-4 border border-medium-blue hover:border-transparent">
            {t('finalSection.button')}
          </button>
        </a>
      </section>
    </div>
  );
};

export default FinalSection;
