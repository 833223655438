import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import { feature } from 'topojson-client';

interface IWorldMapProps {
  scale: number;
  cx: number;
  cy: number;
  initRotation: number;
  rotationSpeed: number;
  coordinatesData: { name: string; coords: [number, number] }[];
}

const WorldMap: React.FC<IWorldMapProps> = ({ scale, cx, cy, initRotation, rotationSpeed, coordinatesData }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [rotation, setRotation] = useState<number>(initRotation);
  const [selectedMarker, setSelectedMarker] = useState<{ name: string; coords: [number, number] } | null>(null);

  const projection = d3.geoOrthographic().scale(scale).translate([cx, cy]).rotate([rotation, 0]);
  const pathGenerator = d3.geoPath().projection(projection);

  const loadMapData = async () => {
    const svg = d3.select(svgRef.current);
    // const data = await d3.json('https://d3js.org/world-110m.v1.json') as any;
    const data = await d3.json('../../../data/world-110m.json') as any;
    const countries = feature(data, data.objects.countries);

    svg.selectAll('.country')
      .data(countries.features)
      .join('path')
      .attr('class', 'country')
      .attr('d', pathGenerator)
      .style('fill', '#ddd')
      .style('stroke', '#333');

    svg.selectAll('.dot')
      .data(coordinatesData)
      .join('circle')
      .attr('class', 'dot')
      .attr('cx', d => projection(d.coords)[0])
      .attr('cy', d => projection(d.coords)[1])
      .attr('r', 'sm' ? 4 : 5) // Responsive radius based on screen size
      .style('fill', 'red')
      .style('stroke', '#fff')
      .style('opacity', d => isPointVisible(d.coords, projection) ? 1 : 0)
      .style('cursor', 'pointer')
      .on('click', (_, i) => handleMarkerClick(i));
  };

  useEffect(() => {
    loadMapData();
  }, [rotation]);

  const handleMarkerClick = (marker: { name: string; coords: [number, number] }) => {
    setSelectedMarker(marker);
  };

  const isPointVisible = (coords: [number, number], projection: d3.geoProjection) => {
    const rotatedCoords = projection.rotate();
    const [x, y] = coords;
    const [lambda, phi] = d3.geoRotation(rotatedCoords)([x, y]);
    return lambda >= -90 && lambda <= 90; // Only show points within the front-facing hemisphere
  };

  useEffect(() => {
    const rotate = () => {
      let newRotation = rotation + rotationSpeed;
      if (newRotation >= 360) newRotation -= 360;
      setRotation(newRotation);
    };
    const timer = d3.timer(rotate);
    return () => timer.stop();
  }, [rotation, rotationSpeed]);

  return (
    <div className="relative">
      <svg ref={svgRef} 
      width={scale * 3} 
      height={scale * 3} 
      viewBox="0 0 800 450"
      className="w-full h-fit h-[40rem] lg:h-[50rem]"
       >
        <g>
          <circle fill="#f2f2f2" cx={cx} cy={cy} r={scale} />
        </g>
      </svg>
      {selectedMarker && (
        <div className="absolute left-1/2 bottom-4 transform -translate-x-1/2 p-4 bg-zinc-100 rounded-lg shadow-lg text-center">
          <strong className="block text-lg font-semibold">{selectedMarker.name}</strong>
          <p className="text-sm text-gray-600">
            Coordinates: {selectedMarker.coords[0].toFixed(2)}, {selectedMarker.coords[1].toFixed(2)}
          </p>
        </div>
      )}
    </div>
  );
};

export default WorldMap;