import React, { useEffect, useState } from "react";
import '../components/styles/App.css';
import { useLocation } from "react-router-dom";
import { scroller } from "react-scroll";
import LazyLoad from 'react-lazyload';
import Footer from '../components/atoms/Footer/Footer.jsx';
import OurCompanyVegetal from '../components/organisms/OurCompanyVegetal/OurCompanyVegetal.jsx';
import OurCompanyIndustrial from '../components/organisms/OurCompanyIndustrial/OurCompanyIndustrial.jsx';


const IndustrialSection = () => {
    return (
        <>
            <LazyLoad height={200} offset={100}>
                <OurCompanyIndustrial />
            </LazyLoad>
            <LazyLoad height={200} offset={100}>
                <Footer />
            </LazyLoad>
        </>
    );
};


export default IndustrialSection;