import React, { useState } from "react";
import "../components/styles/App.css";
import Footer from "../components/atoms/Footer/Footer.jsx";
import SignUpForm from '../components/organisms/SignUp/SignUpForm.jsx'

function Signup() {
  return (
    <>
      <SignUpForm />
      <Footer />
    </>
  );
}

export default Signup;