import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ScrollReveal from "scrollreveal";
import VideoBackground from "../../../assets/video/HeroSectionVideo.mp4";

const HeroSection = () => {
  const { t } = useTranslation();

  useEffect(() => {
    ScrollReveal().reveal('.hero-section-content h1, .hero-section-content a', {
      origin: 'bottom',
      distance: '20px',
      duration: 1000,
      delay: 200,
      interval: 100,
      reset: false
    });
  }, []);

  return (
    <div className="hero-section relative h-screen w-full overflow-hidden">
      <video
        className="absolute top-0 left-0 w-full h-full object-cover"
        src={VideoBackground}
        autoPlay
        loop
        muted
        playsInline
      ></video>
      <div className="hero-section-overlay absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
      <div className="hero-section-content relative z-10 flex flex-col items-center justify-center h-full text-center text-white font-sans p-6 md:p-20">
        <h1
          className="text-2xl md:text-4xl font-sans font-bold mt-6 mb-10"
          dangerouslySetInnerHTML={{ __html: t('hero.headline') }}
        ></h1>
        <a
          href="https://grafana.intelectronica.com.co"
          className="start-button"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="bg-transparent mr-auto hover:bg-medium-blue text-medium-blue hover:text-white rounded shadow hover:shadow-lg py-2 px-4 border border-medium-blue hover:border-transparent">
            <i className="fa-solid fa-arrow-up-right-from-square text-xl pr-2"></i>
            {t('hero.cta')}
          </button>
        </a>
      </div>
    </div>
  );
};

export default HeroSection;