import React, { useState, useEffect } from "react";
import { Element } from "react-scroll";
import ScrollReveal from "scrollreveal";
import CenterCarousel from "../../atoms/Carousel/Carousel.jsx";
import img1 from "../../../assets/img/VEGETAL/Vegetal_6720*4480/DB_DO&AT_6720*4480.png";
// import img2 from "../../../assets/img/maintance.jpg";
import img2 from "../../../assets/img/VEGETAL/Vegetal_6720*4480/IAS_6720*4480.jpg";
// import img3 from "../../../assets/img/advisory.jpg";
import img3 from "../../../assets/img/VEGETAL/Vegetal_6720*4480/Tahami_4.png";
import img4 from "../../../assets/img/VEGETAL/OTA-SAS.png";

import vegetalLogo from "../../../assets/img/OurServices/VegetalLogo.png";
import { useTranslation } from 'react-i18next';
import '@fortawesome/fontawesome-free/css/all.min.css';
import bgImage from '../../../assets/img/BackGround/ITLCTRNC_FONDO_VEGETAL.jpg';
import vegetalLogo01 from '../../../assets/img/VegetalLogos/VEGETAL_LOGO_01.svg'
import vegetalLogo02 from '../../../assets/img/VegetalLogos/VEGETAL_LOGO_02.svg'
import vegetalLogo03 from '../../../assets/img/VegetalLogos/VEGETAL_LOGO_03.svg'
import vegetalLogo04 from '../../../assets/img/VegetalLogos/VEGETAL_LOGO_04.svg'
import vegetalLogo05 from '../../../assets/img/VegetalLogos/VEGETAL_LOGO_05.svg'
import vegetalLogo06 from '../../../assets/img/VegetalLogos/VEGETAL_LOGO_06.svg'
import LazyLoad from 'react-lazyload';  // Import LazyLoad

const carouselVegetalImages = [
  {
    src: img1,
    alt: "Horti Image",
    key: "vegetal"
  },
  {
    src: img4,
    alt: "Maintenance Image",
    key: "maintenanceVegetal"
  },
  {
    src: img2,
    alt: "Advisory Image",
    key: "advisorVegetal"
  },
  {
    src: img3,
    alt: "Visuzalization Image",
    key: "visualizationVegetal"
  }

];

const VegetalSection = () => {
  const [currentVegetalSlide, setCurrentVegetalSlide] = useState(0);
  const { t } = useTranslation();

  const handleVegetalClick = (index) => {
    setCurrentVegetalSlide(index);
  };

  useEffect(() => {
    ScrollReveal().reveal('.vegetal-logo, .our-company-content, .hero_buttons, .carousel-text, .carousel-images, .carousel-description, .service-card', {
      origin: 'bottom',
      distance: '20px',
      duration: 1000,
      delay: 200,
      interval: 100,
      reset: false
    });
  }, []);

  return (
    <section
      className="p-6 md:p-12 lg:p-20 bg-cover bg-center text-black font-sans"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className="flex flex-col justify-center items-center space-y-10 text-center">
        <img src={vegetalLogo} alt="Vegetal Logo" className="vegetal-logo mt-36 mb-8 h-16 sm:h-20 md:h-24 w-auto" />

        <h1 className="our-company-content mt-4 text-2xl sm:text-3xl md:text-4xl font-bold mb-4 px-4"
        dangerouslySetInnerHTML={{ __html: t('vegetalPage.vegetalHeroTitle') }}
        >
          {/* {t('vegetalPage.vegetalHeroTitle')} */}
          
        </h1>

        <div className="hero_buttons mt-6 md:mt-20 flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
          <a
            href="/demo"
            className="start-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button href="/demo" className="bg-transparent mr-auto hover:bg-dark-green text-dark-green hover:text-white rounded shadow hover:shadow-lg py-2 px-4 border border-dark-green hover:border-transparent">
              <i className="fa-solid fa-arrow-up-right-from-square text-xl pr-2"></i>
              {t('vegetalPage.demoButton')}
            </button>
          </a>
          <a
            href="https://wa.me/+573127725739"
            className="start-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="bg-transparent mr-auto hover:bg-dark-green text-dark-green hover:text-white rounded shadow hover:shadow-lg py-2 px-4 border border-dark-green hover:border-transparent">
              <i className="fa-brands fa-whatsapp text-2xl pr-2"></i>
              {t('vegetalPage.callActionButton')}
            </button>
          </a>
        </div>
      </div>

      <section className=" p-6 md:p-12 lg:p-20 text-black font-sans">
        <div className="carousel-description mt-10 w-full grid grid-cols-1 sm:grid-cols-2 gap-6">
          {carouselVegetalImages.map((image, index) => (
            <div
              key={index}
              onClick={() => handleVegetalClick(index)}
              className={`cursor-pointer p-4 transition-all duration-300 ease-in-out rounded-lg shadow-lg hover:shadow-xl ${index === currentVegetalSlide ? "bg-light-green" : "bg-zinc-100"}`}
            >
              <h3 className={`text-lg sm:text-xl md:text-2xl ${index === currentVegetalSlide ? "text-black font-bold" : "text-gray-400"} transition-all duration-300 ease-in-out`}>
                {t(`vegetalPage.${image.key}.title`)}
              </h3>
              <h4 className={`text-sm sm:text-md md:text-lg ${index === currentVegetalSlide ? "font-bold mb-2" : "font-normal mb-2"} transition-all duration-300 ease-in-out`}>
                {t(`vegetalPage.${image.key}.subtitle`)}
              </h4>
              {index === currentVegetalSlide && (
                <p className="text-sm sm:text-md font-light transition-opacity duration-300 ease-in-out">
                  {t(`vegetalPage.${image.key}.description`)}
                </p>
              )}
            </div>
          ))}
        </div>

        <div className="carousel-images w-full mt-20 md:mt-40 ">
          <CenterCarousel
            images={carouselVegetalImages}
            activeItem={currentVegetalSlide}
            onItemChange={setCurrentVegetalSlide}
          />
        </div>
      </section>

      {/* <LazyLoad height={200} offset={100} once> */}
      <section className="p-6 mt-10 md:mt-5 md:p-5 lg:p-20 bg-card text-black font-sans">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
          <div className="service-card bg-zinc-100 p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300 flex flex-row items-center text-left">
            <img src={vegetalLogo03} alt="Advisory Icon" className="w-12 sm:w-14 md:w-16 h-12 sm:h-14 md:h-16 mr-4 sm:mr-6" />
            <div>
              {/* <h3 className="text-md sm:text-lg md:text-xl font-bold text-secondary">
                 {t('vegetalPage.advisory.title')}
               </h3> */}
              <p className="text-base sm:text-md text-muted-foreground">
                {t('vegetalPage.ourServices.automationCard')}
              </p>
            </div>
          </div>

          <div className="service-card bg-zinc-100 p-6  rounded-lg shadow-md hover:shadow-lg transition duration-300 flex flex-row items-center text-left">
            <img src={vegetalLogo04} alt="Advisory Icon" className="w-12 sm:w-14 md:w-16 h-12 sm:h-14 md:h-16 mr-4 sm:mr-6" />
            <div>
              {/* <h3 className="text-md sm:text-lg md:text-xl font-bold text-secondary">
                 {t('vegetalPage.supervision.title')}
               </h3> */}
              <p className="text-base sm:text-md text-muted-foreground">
                {t('vegetalPage.ourServices.comunicationCard')}
              </p>
            </div>
          </div>


          <div className="service-card bg-zinc-100 p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300 flex flex-row items-center text-left">
            <img src={vegetalLogo02} alt="Advisory Icon" className="w-12 sm:w-14 md:w-16 h-12 sm:h-14 md:h-16 mr-4 sm:mr-6" />
            <div>
              {/* <h3 className="text-md sm:text-lg md:text-xl font-bold text-secondary">
                 {t('vegetalPage.design.title')}
               </h3> */}
              <p className="text-base sm:text-md text-muted-foreground">
                {t('vegetalPage.ourServices.electricalCard')}
              </p>
            </div>
          </div>

          <div className="service-card bg-zinc-100 p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300 flex flex-row items-center text-left">
            <img src={vegetalLogo01} alt="Advisory Icon" className="w-12 sm:w-14 md:w-16 h-12 sm:h-14 md:h-16 mr-4 sm:mr-6" />
            <div >
              {/* <h3 className="text-md sm:text-lg md:text-xl font-bold text-secondary">
                 {t('vegetalPage.systemIntegration.title')}
               </h3> */}
              <p className="text-base sm:text-md text-muted-foreground">
                {t('vegetalPage.ourServices.technologyCard')}
              </p>
            </div>
          </div>

          <div className="service-card bg-zinc-100 p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300 flex flex-row items-center text-left">
            <img src={vegetalLogo05} alt="Advisory Icon" className="w-12 sm:w-14 md:w-16 h-12 sm:h-14 md:h-16 mr-4 sm:mr-6" />
            <div>
              {/* <h3 className="text-md sm:text-lg md:text-xl font-bold text-secondary">
                 {t('vegetalPage.maintenanceSupport.title')}
               </h3> */}
              <p className="text-base sm:text-md text-muted-foreground">
                {t('vegetalPage.ourServices.healthCard')}
              </p>
            </div>
          </div>

          <div className="service-card bg-zinc-100 p-6  rounded-lg shadow-md hover:shadow-lg transition duration-300 flex flex-row items-center text-left">
            <img src={vegetalLogo04} alt="Advisory Icon" className="w-12 sm:w-14 md:w-16 h-12 sm:h-14 md:h-16 mr-4 sm:mr-6" />
            <div>
              {/* <h3 className="text-md sm:text-lg md:text-xl font-bold text-secondary">
                 {t('vegetalPage.supervision.title')}
               </h3> */}
              <p className="text-base sm:text-md text-muted-foreground">
                {t('vegetalPage.ourServices.visualizationCard')}
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* </LazyLoad> */}
    </section>
  );
};

export default VegetalSection;

