import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import '@fortawesome/fontawesome-free/css/all.min.css';
import logo from '../../../assets/img/INTELECTRONICALOGO.png';
import { useTranslation } from 'react-i18next';


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mobileDropdownOpen, setMobileDropdownOpen] = useState(false);
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);

  const { i18n, t } = useTranslation();
  const navbarRef = useRef(null);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleMobileDropdownToggle = (e) => {
    e.stopPropagation();
    setMobileDropdownOpen(!mobileDropdownOpen);
  };

  const handleDropdownToggle = (e) => {
    e.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };

  const handleLanguageDropdownToggle = (e) => {
    e.stopPropagation();
    setLanguageDropdownOpen(!languageDropdownOpen);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setMobileDropdownOpen(false);
        setLanguageDropdownOpen(false);
        setDropdownOpen(false);
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <header className="absolute top-5 left-0 w-full z-30 bg-transparent">
      {/* Large Screen Navbar */}
      <nav
        ref={navbarRef}
        className="hidden md:flex max-w-[85rem] mx-auto bg-neutral-900 bg-opacity-50 shadow-lg rounded-[28px] py-3 px-5 items-center justify-between transition-all duration-300"
      >
        <a href="/" aria-label="Intelectronica" className="flex-none text-xl font-semibold">
          <img src={logo} alt="Logo" className="w-60 h-auto" />
        </a>

        <div
          className={`hs-collapse ${isOpen ? 'block' : 'hidden'} md:flex flex flex-row items-center space-x-3 md:space-x-10 md:w-auto w-fit mt-5 ml-5 md:mt-0`}
          id="navbar-collapse"
        >
          <a
            href="https://wa.me/+573127725739"
            className="text-white hover:text-gray-300 transition duration-300 text-xl"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-whatsapp"></i>
          </a>
          <a
            href="https://www.instagram.com/intelectronica_sas/"
            className="text-white hover:text-gray-300 transition duration-300 text-xl"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram"></i>
          </a>
          <a
            href="https://www.linkedin.com/company/intelectronica-s-a-s/"
            className="text-white hover:text-gray-300 transition duration-300 text-xl"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-linkedin-in"></i>
          </a>

          <div className="relative">
            <button
              className="text-white hover:text-gray-300 text-xl"
              aria-controls="language-menu"
              aria-haspopup="true"
              onClick={handleLanguageDropdownToggle}
            >
              <i className="fa fa-light fa-globe"></i>
              <svg
                className={`inline ml-1 w-4 h-4 transform ${languageDropdownOpen ? 'rotate-180' : 'rotate-0'} transition-transform duration-500 ease-in-out`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>

            <div
              className={`absolute right-0 mt-10 w-48 bg-neutral-900 bg-opacity-50 text-white rounded-md shadow-lg transform transition-all duration-500 ease-in-out 
              ${languageDropdownOpen ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-[-10px] pointer-events-none'}`}
            >
              <Link
                onClick={() => {
                  changeLanguage('en');
                  setLanguageDropdownOpen(false);
                }}
                className="block px-4 py-2 hover:bg-neutral-700 rounded-md transition duration-300"
              >
                {t('navbar.english')}
              </Link>

              <Link
                onClick={() => {
                  changeLanguage('es');
                  setLanguageDropdownOpen(false);
                }}
                className="block px-4 py-2 hover:bg-neutral-700 rounded-md transition duration-300"
              >
                {t('navbar.spanish')}
              </Link>
            </div>

          </div>
        </div>

        <div className="flex items-center space-x-10">
          <ul className="flex space-x-8">
            <li>
              <Link to="/" className="text-white hover:text-gray-300 transition duration-300 text-xl">
                {t('navbar.home')}
              </Link>
            </li>
            <li>
              <Link to="/?scrollTo=ourServices" className="text-white hover:text-gray-300 transition duration-300 text-xl">
                {t('navbar.services')}
              </Link>
            </li>
            <li className="relative">
              <button
                className="text-white hover:text-gray-300 transition duration-300 text-xl"
                onClick={handleDropdownToggle}>
                {t('navbar.about')}
                <svg className={`inline ml-1 w-4 h-4 transform ${dropdownOpen ? 'rotate-180' : 'rotate-0'} transition-transform duration-500 ease-in-out`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              <div
                className={`absolute right-0 mt-10 w-48 bg-neutral-900 bg-opacity-50 text-white rounded-md shadow-lg transform transition-all duration-300 ease-in-out 
              ${dropdownOpen ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-[-10px] pointer-events-none'}`}
              >
                <Link
                  to="/vegetalSection"
                  className="block px-4 py-2 hover:bg-neutral-700 rounded-md transition duration-300"
                  onClick={() => setDropdownOpen(false)}
                >
                  {t('navbar.vegetal')}
                </Link>
                <Link
                  to="/industrialSection"
                  className="block px-4 py-2 hover:bg-neutral-700 rounded-md transition duration-300"
                  onClick={() => setDropdownOpen(false)}
                >
                  {t('navbar.industrial')}
                </Link>
              </div>

            </li>
            <li>
              {/* <Link to="https://grafana.intelectronica.com.co" className="bg-medium-blue  text-white px-3 py-2 rounded-md hover:bg-neutral-700 transition duration-300 text-xl"> */}
              <Link to="https://grafana.intelectronica.com.co" className="bg-transparent mr-auto hover:bg-medium-blue text-medium-blue hover:text-white rounded shadow hover:shadow-lg py-2 px-4 border border-medium-blue hover:border-transparent text-xl">
                <i className="fa fa-light fa-right-to-bracket  pr-2"></i>
                {t('navbar.login')}
              </Link>
            </li>
            <li>
              <Link to="/demo" className="bg-transparent mr-auto hover:bg-medium-blue text-medium-blue hover:text-white rounded shadow hover:shadow-lg py-2 px-4 border border-medium-blue hover:border-transparent text-xl">
                <i className="fa fa-light fa-arrow-up-right-dots   pr-2"></i>
                {t('navbar.demo')}
              </Link>
            </li>
          </ul>
        </div>
      </nav>

      {/* Small Screen Navbar */}
      <nav className="absolute top-0 left-1/2 transform -translate-x-1/2 w-11/12 md:hidden items-center justify-between bg-neutral-900 bg-opacity-80 shadow-lg py-3 px-5 rounded-[28px] transition-all duration-300 z-50" ref={navbarRef}>
        <div className="flex items-center justify-between">
          <a href="/" aria-label="Intelectronica" className="flex-none text-xl font-semibold">
            <img src={logo} alt="Logo" className="w-44 h-auto" />
          </a>
          <button
            type="button"
            className="flex justify-center items-center text-sm font-semibold rounded-full bg-transparent text-white p-2 transition-transform duration-300 ease-in-out"
            onClick={handleToggle}
            aria-controls="navbar-collapse"
            aria-expanded={isOpen}
            aria-label="Toggle navigation"
          >
            {isOpen ? (
              <svg
                className="w-6 h-6 transform rotate-90 transition-transform duration-300 ease-in-out"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            ) : (
              <svg
                className="w-6 h-6 transform rotate-0 transition-transform duration-300 ease-in-out"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            )}
          </button>
        </div>

        {/* Small Screen Collapsible Menu */}
        <div
          className={`mt-2 overflow-hidden transition-all duration-500 ease-in-out transform ${isOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'} shadow-lg rounded-lg`}
        >
          {/* Language Selector */}
          <div className="flex justify-center space-x-4 text-xl m-4">
            <button className="lang-button text-white hover:text-gray-300"
              onClick={() => {
                changeLanguage('en')
                setIsOpen(false);
              }} >
              EN
            </button>
            <button className="lang-button text-white hover:text-gray-300"
              onClick={() => {
                changeLanguage('es')
                setIsOpen(false);
              }}>
              ES
            </button>
          </div>

          {/* Social Media Links */}
          <div className="flex justify-center space-x-4 mb-1">
            <a href="https://wa.me/+573127725739" className="p-3 text-white text-2xl hover:text-gray-300" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-whatsapp"></i>
            </a>
            <a href="https://www.instagram.com/intelectronica_sas" className="p-3 text-white text-2xl hover:text-gray-300" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.linkedin.com/company/intelectronica-s-a-s/" className="p-3 text-white text-2xl hover:text-gray-300" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin-in"></i>
            </a>
          </div>

          {/* Navigation Links */}
          <ul className="flex flex-col space-y-2 p-4 text-center">
            <li>
              <Link to="/" className="text-white text-2xl hover:text-gray-300 block" onClick={() => setIsOpen(false)}>
                {t('navbar.home')}
              </Link>
            </li>
            <li>
              <Link to="/?scrollTo=ourServices" className="text-white text-2xl hover:text-gray-300 block" onClick={() => setIsOpen(false)}>
                {t('navbar.services')}
              </Link>
            </li>
            <li className="relative">
              <button
                onClick={handleMobileDropdownToggle}
                className="text-white text-2xl ml-1 hover:text-gray-300 block w-full text-center"
              >
                {t('navbar.about')}
                <svg className={`inline ml-1 w-4 h-4 transform ${mobileDropdownOpen ? 'rotate-180' : 'rotate-0'} transition-transform duration-500 ease-in-out`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              <div
                className={`ml-4 mt-2 transform transition-all duration-300 ease-in-out 
              ${mobileDropdownOpen ? 'opacity-100 translate-y-0 max-h-screen' : 'opacity-0 translate-y-[-10px] max-h-0 overflow-hidden pointer-events-none'}`}
              >
                <Link
                  to="/vegetalSection"
                  className="text-white mr-5 text-xl hover:text-gray-300 block"
                  onClick={() => {
                    setMobileDropdownOpen(false);
                    setIsOpen(false);
                  }}
                >
                  {t('navbar.vegetal')}
                </Link>
                <Link
                  to="/industrialSection"
                  className="text-white mr-5 text-xl hover:text-gray-300 block"
                  onClick={() => {
                    setMobileDropdownOpen(false);
                    setIsOpen(false);
                  }}
                >
                  {t('navbar.industrial')}
                </Link>
              </div>
            </li>
            <li>
              <Link
                to="https://grafana.intelectronica.com.co"
                className="text-white block px-3 py-2 bg-transparent mr-auto hover:bg-medium-blue text-medium-blue hover:text-white rounded shadow hover:shadow-lg py-2 px-4 border border-medium-blue hover:border-transparent text-xl"
                onClick={() => setIsOpen(false)}
              >
                {t('navbar.login')}
              </Link>
            </li>
            <li>
              <Link
                to="/demo"
                // className="bg-medium-blue text-white block px-3 py-2 rounded-md hover:bg-neutral-700 text-xl "
                className = "text-white block px-3 py-2 bg-transparent mr-auto hover:bg-medium-blue text-medium-blue hover:text-white rounded shadow hover:shadow-lg py-2 px-4 border border-medium-blue hover:border-transparent text-xl"
                onClick={() => setIsOpen(false)}
              >
                {t('navbar.demo')}
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
