import React, { useEffect } from 'react';
import { Element } from 'react-scroll';
import ScrollReveal from 'scrollreveal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faDraftingCompass, faCogs, faTools, faUserShield,faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const OurServices = () => {
  const { t } = useTranslation();
  

  useEffect(() => {
    ScrollReveal().reveal('.service-card', {
      origin: 'bottom',
      distance: '20px',
      duration: 1000,
      delay: 200,
      interval: 100,
      reset: false
    });
  }, []);

  
  return (
    // <Element name="ourServices" className="element our-services-section">
      <section className="p-2 md:p-20 bg-card bg-zinc-300 text-black font-sans">
        <h2 className="text-xl md:text-3xl font-bold text-primary mt-6 mb-14 md:mb-20 text-center text-medium-blue">
          {t('ourServices.title')}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
          <div className="service-card bg-popover p-4 rounded-lg shadow-md hover:shadow-lg transition duration-300 flex flex-col items-center text-center">
            <FontAwesomeIcon icon={faBriefcase} className="text-primary text-4xl mb-4" />
            <h3 className="text-lg md:text-xl font-bold text-secondary">{t('ourServices.advisory.title')}</h3>
            <p className="text-muted-foreground">{t('ourServices.advisory.description')}</p>
          </div>
          <div className="service-card bg-popover p-4 rounded-lg shadow-md hover:shadow-lg transition duration-300 flex flex-col items-center text-center">
            <FontAwesomeIcon icon={faDraftingCompass} className="text-primary text-4xl mb-4" />
            <h3 className="text-lg md:text-xl font-bold text-secondary">{t('ourServices.design.title')}</h3>
            <p className="text-muted-foreground">{t('ourServices.design.description')}</p>
          </div>
          <div className="service-card bg-popover p-4 rounded-lg shadow-md hover:shadow-lg transition duration-300 flex flex-col items-center text-center">
            <FontAwesomeIcon icon={faCogs} className="text-primary text-4xl mb-4" />
            <h3 className="text-lg md:text-xl font-bold text-secondary">{t('ourServices.systemIntegration.title')}</h3>
            <p className="text-muted-foreground">{t('ourServices.systemIntegration.description')}</p>
          </div>
          <div className="service-card bg-popover p-4 rounded-lg shadow-md hover:shadow-lg transition duration-300 flex flex-col items-center text-center">
            <FontAwesomeIcon icon={faTools} className="text-primary text-4xl mb-4" />
            <h3 className="text-lg md:text-xl font-bold text-secondary">{t('ourServices.maintenanceSupport.title')}</h3>
            <p className="text-muted-foreground">{t('ourServices.maintenanceSupport.description')}</p>
          </div>
          <div className="service-card bg-popover p-4 rounded-lg shadow-md hover:shadow-lg transition duration-300 flex flex-col items-center text-center">
            <FontAwesomeIcon icon={faUserShield} className="text-primary text-4xl mb-4" />
            <h3 className="text-lg md:text-xl font-bold text-secondary">{t('ourServices.supervision.title')}</h3>
            <p className="text-muted-foreground">{t('ourServices.supervision.description')}</p>
          </div>
          <div className="service-card bg-popover p-4 rounded-lg shadow-md hover:shadow-lg transition duration-300 flex flex-col items-center text-center">
            <FontAwesomeIcon icon={faCartShopping} className="text-primary text-4xl mb-4" />
            <h3 className="text-lg md:text-xl font-bold text-secondary">{t('ourServices.sales.title')}</h3>
            <p className="text-muted-foreground">{t('ourServices.sales.description')}</p>
          </div>
        </div>
      </section>
    // </Element>
  );
};

export default OurServices;
