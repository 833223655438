import React from 'react';
import GraphsPage from '../../molecules/GraphsPage/GraphsPage.jsx';
import { useTranslation } from 'react-i18next';

const DemoSection = () => {
  const { t } = useTranslation(); 
  const graphsData = [
    {
      src: "https://grafana.intelectronica.com.co/d-solo/pXeiX8WVe/marquesado-project?orgId=1&from=1719035164350&to=1719639964350&panelId=88",
      title: `${t('demoPage.Graph')} 1`,
      width: "450",
      height: "450"
    },
    {
      src: "https://grafana.intelectronica.com.co/d/pXeiX8WVe/marquesado-project?orgId=1&from=1719035659520&to=1719640459520&viewPanel=42",
      title: `${t('demoPage.Graph')} 2`,
      width: "450",
      height: "450"
    },
  ];

  return (
    <div>
      <GraphsPage graphs={graphsData} />
    </div>
  );
}

export default DemoSection;
