// import React, { useState } from 'react';
// import GraphCard from '../../atoms/GraphCard/GraphCard';
// import GraphModal from '../../atoms/GraphModal/GraphModal';
// import { useTranslation } from 'react-i18next';

// const GraphsPage = ({ graphs = [] }) => {
//   const [selectedGraph, setSelectedGraph] = useState(null);

//   const openModal = (graph) => {
//     setSelectedGraph(graph);
//   };

//   const closeModal = () => {
//     setSelectedGraph(null);
//   };

//   const { t } = useTranslation();

//   return (
//     <section className='p-6 md:p-12 lg:p-20 bg-dark-black-blue bg-center text-white font-sans'>
//         <div className="mt-32 grid grid-cols-1 sm:grid-cols-2 gap-8 items-center mb-20">
//           <div>
//             <h1 className="text-4xl font-bold text-white">{t('demoPage.demoTitle')}</h1>
//             <a href="#" className="text-lg text-white">{t('demoPage.demoSubtitle')}</a>
//           </div>
//           <p className="text-lg max-w-lg">
//           {t('demoPage.demoText')}
//           </p>
//         </div>

//         <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mb-20">
//           {graphs.length === 0 ? (
//             <p>No graphs available</p>
//           ) : (
//             graphs.map((graph, index) => (
//               <GraphCard
//                 key={index}
//                 src={graph.src}
//                 title={`${t('demoPage.Graph')} ${index + 1}`}
//                 width={graph.width}
//                 height={graph.height}
//                 onClick={() => openModal(graph)}
//                 className="border-2 border-dark-blue"
//               />
//             ))
//           )}
//         </div>

//         {/* Graph Modal */}
//         {selectedGraph && (
//           <GraphModal
//             isOpen={!!selectedGraph}
//             src={selectedGraph.src}
//             title={selectedGraph.title}
//             onClose={closeModal}
//           />
//         )}
     
//     </section>
//   );
// };

// export default GraphsPage;


import React, { useState, useEffect } from 'react';
import GraphCard from '../../atoms/GraphCard/GraphCard';
import GraphModal from '../../atoms/GraphModal/GraphModal';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import ScrollReveal from 'scrollreveal';

const GraphsPage = ({ graphs = [] }) => {
  const [selectedGraph, setSelectedGraph] = useState(null);

  const openModal = (graph) => {
    setSelectedGraph(graph);
  };

  const closeModal = () => {
    setSelectedGraph(null);
  };

  const { t } = useTranslation();

  useEffect(() => {
    ScrollReveal().reveal('.demo-title,.demo-subtitle,.demo-text,.graph-card', {
      origin: 'bottom',
      distance: '20px',
      duration: 1000,
      delay: 200,
      interval: 100,
      reset: false,
    });
  }, []);

  return (
    <section className='p-6 md:p-12 lg:p-20 bg-dark-black-blue bg-center text-white font-sans'>
      <div className="mt-32 grid grid-cols-1 sm:grid-cols-2 gap-8 items-center mb-20">
        <div>
          <h1 className="demo-title text-4xl font-bold text-white">{t('demoPage.demoTitle')}</h1>
          <h2 className="demo-subtitle text-lg text-white">{t('demoPage.demoSubtitle')}</h2>
        </div>
        <p className="demo-text text-lg max-w-lg">
          {t('demoPage.demoText')}
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mb-20">
        {graphs.length === 0 ? (
          <p>No graphs available</p>
        ) : (
          graphs.map((graph, index) => (
              <div className="graph-card"> 
                <GraphCard
                  src={graph.src}
                  title={`${t('demoPage.Graph')} ${index + 1}`}
                  width={graph.width}
                  height={graph.height}
                  onClick={() => openModal(graph)}
                  className="border-2 border-dark-blue"
                />
              </div>
          ))
        )}
      </div>

      {selectedGraph && (
        <GraphModal
          isOpen={!!selectedGraph}
          src={selectedGraph.src}
          title={selectedGraph.title}
          onClose={closeModal}
        />
      )}
    </section>
  );
};

export default GraphsPage;
