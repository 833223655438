import React, { useState } from "react";
import "../components/styles/App.css";
import Footer from "../components/atoms/Footer/Footer.jsx";
import DemoSection from "../components/organisms/DemoSection/DemoSection.jsx";
import LazyLoad from 'react-lazyload';

function Demo() {
  return (
    <>
      <LazyLoad height={200} offset={100}>
        <DemoSection />
      </LazyLoad>
      <LazyLoad height={200} offset={100}>
        <Footer />
      </LazyLoad>
    </>
  );
}

export default Demo;