
import React from 'react';
import { Transition } from '@headlessui/react';
import './GraphModal.css';

const GraphModal = ({ isOpen, src, title, onClose }) => {
  return (
    <Transition show={isOpen} as={React.Fragment}>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <Transition.Child
          as="div"
          enter="transition transform ease-out duration-300"
          enterFrom="opacity-0 scale-75"
          enterTo="opacity-100 scale-100"
          leave="transition transform ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-75"
          className="modal-content relative bg-zinc-100 p-4 rounded-lg shadow-lg"
        >
          <button onClick={onClose} className="close-button text-black float-right text-2xl">✕</button>
          <h2 className="modal-title text-light-blue text-2xl mb-4">{title}</h2>
          <iframe 
            src={src} 
            width="100%" 
            height="500" 
            frameBorder="0" 
            className="rounded-md"
            title={title}
          ></iframe>
        </Transition.Child>
      </div>
    </Transition>
  );
};

export default GraphModal;
