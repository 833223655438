import React, { useEffect } from 'react';
import ScrollReveal from 'scrollreveal';
import LogoSlider from "../../atoms/LogoSlider/LogoSlider"; 
import SimacLogo from "../../../assets/img/Partners/Simac.png";
import LumenLogo from '../../../assets/img/Partners/Lumen.png';
import MetalandesLogo from '../../../assets/img/Partners/Metalandes.png';
import SaluceLogo from '../../../assets/img/Partners/Saluce.png';
import Cstainable from '../../../assets/img/Partners/CStainable.png';
import Co2groLogo from '../../../assets/img/Partners/CO2GRO.png';
import Orkantek from '../../../assets/img/Partners/Orkantek.png';
import { useTranslation } from 'react-i18next';

const slides = [
  { image: SimacLogo },
  { image: MetalandesLogo },
  { image: LumenLogo },
  { image: Cstainable },
  { image: Orkantek },
  // { image: SaluceLogo },
  // { image: Co2groLogo },
];

const OurPartners = () => {
  const { t } = useTranslation();

  useEffect(() => {
    ScrollReveal().reveal('.logo-slider', {
      origin: 'bottom',
      distance: '30px',
      duration: 1000,
      delay: 200,
      interval: 100,
      reset: false,
    });
  }, []);

  return (
    <section className="p-6 md:p-20 bg-dark-blue text-medium-blue">
      <h2 className="text-xl md:text-3xl font-bold text-medium-blue mt-10 md:mt-30 mb-4 md:mb-20 text-center">
        {t('ourPartners.title')}
      </h2>
      <div className="logo-slider">
        <LogoSlider images={slides} />
      </div>
    </section>
  );
};

export default OurPartners;
