import React from 'react';
import { BoltLoader } from "react-awesome-loaders";

const BoltLoaderComponent = () => {
  return (
    <div className="flex justify-center items-center h-screen bg-dark-black-blue">
      <BoltLoader
        className={"loaderbolt"}
        boltColor={"#21BFFB"}          
        backgroundBlurColor={"#21BFFB"} 
      />
    </div>
  );
};

export default BoltLoaderComponent;
