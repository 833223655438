// import { Carousel } from "react-responsive-carousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import "../../styles/App.css";

// function Carrousel({ images, setCurrentSlide, currentSlide }) {
//   return (
//     <div className="box mx-auto p-6 bg-white rounded-lg shadow-lg">
//       <Carousel
//         useKeyboardArrows={true}
//         infiniteLoop={true}
//         showThumbs={false}
//         showStatus={false}
//         autoFocus={true}
//         selectedItem={currentSlide}
//         onChange={(index) => setCurrentSlide(index)}
//         className="carousel-container"
//       >
//         {images.map((image, index) => (
//           <div className="slide" key={index}>
//             <img
//               alt={image.alt}
//               src={image.src}
//               className="rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-105"
//             />
//           </div>
//         ))}
//       </Carousel>
//     </div>
//   );
// }

// export default Carrousel;


// import { Carousel } from "react-responsive-carousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import "../../styles/App.css";

// function Carrousel({ images, setCurrentSlide, currentSlide }) {
//   return (
//     <div className="box mx-auto p-6 bg-white rounded-lg shadow-lg">
//       <Carousel
//         useKeyboardArrows={true}
//         infiniteLoop={true}
//         showThumbs={false}
//         showStatus={false}
//         autoFocus={true}
//         selectedItem={currentSlide}
//         onChange={(index) => setCurrentSlide(index)}
//         className="carousel-container"
//         autoPlay={true} // Automatically cycle through slides
//         interval={5000} // Set the interval for autoplay
//         transitionTime={600} // Set the transition time between slides
//         stopOnHover={true} // Pause autoplay on hover
//         showIndicators={true} // Show dot indicators
//       >
//         {images.map((image, index) => (
//           <div className="slide" key={index}>
//             <img
//               alt={image.alt}
//               src={image.src}
//               className="rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-105"
//             />
//           </div>
//         ))}
//       </Carousel>
//     </div>
//   );
// }

// export default Carrousel;
// import { Carousel } from "react-responsive-carousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import "../../styles/App.css";

// function Carrousel({ images, setCurrentSlide, currentSlide }) {
//   return (
//     <div className="box mx-auto p-8 bg-white rounded-lg shadow-lg max-w-6xl">
//       <Carousel
//         useKeyboardArrows={true}
//         infiniteLoop={true}
//         showThumbs={false}
//         showStatus={false}
//         autoFocus={true}
//         selectedItem={currentSlide}
//         onChange={(index) => setCurrentSlide(index)}
//         className="carousel-container"
//         autoPlay={true}
//         interval={5000}
//         swipeable={true}
//         emulateTouch={true}
//         transitionTime={600}
//         stopOnHover={true}
//         showIndicators={true}
//         centerMode={true}
//         centerSlidePercentage={50}
//       >
//         {images.map((image, index) => (
//           <div className="slide w-full h-[400px]" key={index}>
//             <img
//               alt={image.alt}
//               src={image.src}
//               className="rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-105 w-full h-full object-cover"
//             />
//           </div>
//         ))}
//       </Carousel>
//     </div>
//   );
// }

// export default Carrousel;



////////////////////////////////////////////
import React, { useState, useEffect } from "react";
import { useSpringCarousel } from "react-spring-carousel";

export default function CenterCarousel({ images, activeItem, onItemChange }) {
  const [internalActiveItem, setInternalActiveItem] = useState(activeItem);

  const {
    carouselFragment,
    useListenToCustomEvent,
    slideToItem,
  } = useSpringCarousel({
    withLoop: true,
    items: images.map((image, index) => ({
      id: `item-${index}`,
      renderItem: (
        <div className="flex justify-center items-center w-full h-[500px]">
          <img
            src={image.src}
            alt={image.alt}
            className="rounded-lg w-full h-full object-cover transition-transform duration-300 ease-in-out transform"
          />
        </div>
      ),
    })),
    // Mostrar solo una imagen a la vez
    visibleItems:
  typeof window !== "undefined"
    ? window.innerWidth >= 1280
      ? 3
      : window.innerWidth >= 1024
      ? 2
      : 1
    : 1,
    gutter: 0, // Reducir el espacio entre imágenes
    autoplay: true,
    autoplayInterval: 3000,
    disableGestures: false,
  });

  // Escuchar eventos del carrusel para actualizar el estado del elemento activo
  useListenToCustomEvent((event) => {
    if (event.eventName === "onSlideStartChange") {
      const newActiveItem = event.nextItem.index;
      setInternalActiveItem(newActiveItem);
      onItemChange(newActiveItem);
    }
  });

  // Actualizar el carrusel al elemento activo correcto cuando cambian las props
  useEffect(() => {
    if (internalActiveItem !== activeItem) {
      slideToItem(activeItem);
    }
  }, [activeItem, internalActiveItem, slideToItem]);

  return (
    <div className="max-w-10xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex justify-center overflow-hidden">
        {carouselFragment}
      </div>
    </div>
  );
}