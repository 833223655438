
import React from 'react';

const GraphCard = ({ src, title, width, height, onClick }) => {
  return (
    <div
      className="bg-zinc-100 p-4 rounded-md shadow-md cursor-pointer hover:shadow-lg transform transition-all duration-300 hover:scale-105"
      onClick={onClick}
    >
      <h2 className="text-xl text-light-gray mb-2">{title}</h2>
      <iframe
        src={src}
        width={width || '100%'}
        height={height || '200'}
        frameBorder="0"
        className="rounded-md"
        title={title}
      ></iframe>
    </div>
  );
};

export default GraphCard;
