// import React, { useEffect, useState } from "react";
// import { Element } from 'react-scroll';
// import '../components/styles/App.css';
// import { useLocation } from "react-router-dom";
// import { scroller } from "react-scroll";
// import LazyLoad from 'react-lazyload';
// import OurPartners from '../components/organisms/OurPartners/OurPartners.jsx';
// import OurServices from '../components/organisms/OurServices/OurServices.jsx';
// import HeroSection from '../components/organisms/HeroSection/HeroSection.jsx';
// import Timeline from '../components/organisms/Timeline/Timeline.jsx'
// import FinalSection from '../components/organisms/FinalSection/FinalSection.jsx';
// import Footer from '../components/atoms/Footer/Footer.jsx';
// import WorldMap from '../components/organisms/WorldMapSection/WorldMapSection.tsx';

// const Home = () => {
//   const location = useLocation();
//   const [componentsLoaded, setComponentsLoaded] = useState(true);



//   useEffect(() => {
//     if (componentsLoaded) {
//       const params = new URLSearchParams(location.search);
//       console.log(params)
//       const scrollTo = params.get("scrollTo");
//       console.log(scrollTo)
//       if (scrollTo) {
//         scroller.scrollTo(scrollTo, {
//           duration: 800,
//           delay: 0,
//           smooth: "easeInOutQuart",
//           offset: -70,
//         });
//       }
//     }
//   }, [location, componentsLoaded]);

//   return (
//     <>
//       <HeroSection />
//       <LazyLoad height={200} offset={100}>
//         <Timeline />
//       </LazyLoad>
//       <Element name="ourServices" className="element our-services-section">
//         <LazyLoad height={200} offset={100}>
//           <OurServices />
//         </LazyLoad>
//       </Element>
//       <LazyLoad height={200} offset={100}>
//         <WorldMap />
//       </LazyLoad>
//       <LazyLoad height={200} offset={100}>
//         <OurPartners />
//       </LazyLoad>
//       <LazyLoad height={200} offset={100}>
//         <FinalSection />
//       </LazyLoad>
//       <LazyLoad height={200} offset={100}>
//         <Footer />
//       </LazyLoad>
//     </>
//   );
// };

// export default Home;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Element, scroller } from "react-scroll";
import LazyLoad from "react-lazyload";
import HeroSection from '../components/organisms/HeroSection/HeroSection.jsx';
import Timeline from '../components/organisms/Timeline/Timeline.jsx';
import OurServices from '../components/organisms/OurServices/OurServices.jsx';
import WorldMap from '../components/organisms/WorldMapSection/WorldMapSection.tsx';
import OurPartners from '../components/organisms/OurPartners/OurPartners.jsx';
import FinalSection from '../components/organisms/FinalSection/FinalSection.jsx';
import Footer from '../components/atoms/Footer/Footer.jsx';

const Home = () => {
  const location = useLocation(); 
  const [initialLoad, setInitialLoad] = useState(true); 

  useEffect(() => {
    const params = new URLSearchParams(location.search); 
    const scrollTo = params.get("scrollTo"); 

    if (scrollTo && !initialLoad) {
      scroller.scrollTo(scrollTo, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: -70, 
      });
    } else {
      setInitialLoad(false);
    }
  }, [location]); 

  return (
    <>
      <HeroSection />

      <LazyLoad height={200} offset={100}>
        <Timeline />
      </LazyLoad>

      <Element name="ourServices" className="element our-services-section">
        <LazyLoad height={200} offset={100}>
          <OurServices />
        </LazyLoad>
      </Element>

      <LazyLoad height={200} offset={100}>
        <WorldMap />
      </LazyLoad>

      <LazyLoad height={200} offset={100}>
        <OurPartners />
      </LazyLoad>

      <LazyLoad height={200} offset={100}>
        <FinalSection />
      </LazyLoad>

      <LazyLoad height={200} offset={100}>
        <Footer />
      </LazyLoad>
    </>
  );
};

export default Home;

