import React, { useEffect } from 'react';
import WorldMap from '../../molecules/WorldMap/WorldMap.tsx';
import ScrollReveal from 'scrollreveal';
import { useTranslation } from 'react-i18next';

function WorldMapSection() {
  const { t } = useTranslation();
  const coordinatesData: { name: string; coords: [number, number] }[] = [
    { name: 'Toronto', coords: [-79.3832, 43.6532] },
    { name: 'Puebla', coords: [-98.2032, 19.0414] },
    { name: 'Ciudad de Mexico', coords: [-99.1332, 19.4326] },
    { name: 'Bogota', coords: [-74.0721, 4.611] },
    { name: 'Medellin', coords: [-75.5636, 6.2442] },
    { name: 'Cali', coords: [-76.532, 3.4514] },
    { name: 'Riyadh', coords: [46.7219, 24.7136] },
    { name: 'Morocco', coords: [-7.0926, 31.7917] },
    { name: 'KAUST University', coords: [39.0078, 22.3002] },
    { name: 'Riyadh Morocco', coords: [46.7219, 24.7136] },
  ];

  useEffect(() => {
    ScrollReveal().reveal('.worldMap', {
      origin: 'bottom',
      distance: '20px',
      duration: 1000,
      delay: 200,
      interval: 100,
      reset: false,
    });
  }, []);

  return (
    <div className="flex items-center justify-center bg-zinc-300 py-8 px-4">
      <div className="rounded-lg mt-4 md:mt-6 w-full max-w-6xl">
        <h2 className="text-xl md:text-3xl font-bold text-medium-blue mt-6 -mb-16 md:mt-6 md:mb-4 text-center">
          {t('worldMap.title')}
        </h2>

        {/* Map container */}
        <div className="worldMap w-full mb-16">
          <WorldMap
            scale={250}
            cx={400}
            cy={250}
            initRotation={0}
            rotationSpeed={0.10}
            coordinatesData={coordinatesData}
          />
        </div>
      </div>
    </div>
  );
}

export default WorldMapSection;
